.carousel {
  @apply inline-block
    w-full
    mt-10;

  &:nth-last-child(1) {
    @apply -mb-10;
  }
}

.title {
  @apply text-2xl
    font-bold;
}

.top {
  @apply mb-4;
}

.list {
  @apply flex
    items-start
    overflow-x-auto;
}
.list::-webkit-scrollbar {
  display: none;
}

.item {
  /* No Tailwind support */
  -webkit-scroll-snap-align: start;
  scroll-snap-align: start;
  width: 360px;
  @apply mr-7
    mb-10
    flex-shrink-0;
}

.top {
  @apply flex;
}

.arrows {
  @apply flex ml-auto;
}

.arrow {
  @apply p-2
    border
    border-gray-200
    rounded-lg
    bg-white
    cursor-pointer;

  &:hover {
    @apply shadow-md
      duration-150;
  }

  &[data-side="left"] {
    @apply mr-2;

    svg {
      @apply transform
        rotate-180;
    }
  }
}
@media (max-width: 600px) {
  .item {
    width: 100%;
  }
}
