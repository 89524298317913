.navigation_container__nt2HM {
  -ms-flex-item-align: center;
      align-self: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.navigation_count__p1llU {
  cursor: pointer;
  font-weight: 700;
}

.navigation_count__p1llU:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 194, 196, 1);
  color: rgba(0, 194, 196, var(--tw-text-opacity, 1));
}

.navigation_logo__ZxjYJ {
  cursor: pointer;
  display: none;
}

@media (max-width: 767px) {
  .navigation_logo__ZxjYJ {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.navigation_span__A9qBx {
  --tw-text-opacity: 1;
  color: rgba(0, 194, 196, 1);
  color: rgba(0, 194, 196, var(--tw-text-opacity, 1));
}

@media (max-width: 1200px) {
  .navigation_count__p1llU {
    display: none;
  }
}

.searchBar_container__GLn3Y {
  z-index: 9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

  @media (min-width: 768px) {.searchBar_container__GLn3Y {
    width: 400px;
}
  }

  @media (min-width: 1024px) {.searchBar_container__GLn3Y {
    width: 700px;
}
  }

  .searchBar_container__GLn3Y[data-menu="false"] {
  margin-left: auto;
  margin-right: auto;
}

  .searchBar_container__GLn3Y[data-menu="false"] {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

  .searchBar_container__GLn3Y[data-menu="false"] {
  padding-left: 1rem;
  padding-right: 1rem;
}

  .searchBar_container__GLn3Y[data-menu="false"] {
  -webkit-transition-duration: 300ms;
          transition-duration: 300ms;
}

  @media (min-width: 768px) {
      .searchBar_container__GLn3Y[data-menu="false"] .searchBar_input__5QIdH {
        width: 215px;
        -webkit-transition: 0.3s;
        transition: 0.3s;
      }
        .searchBar_container__GLn3Y[data-menu="false"] .searchBar_input__5QIdH:focus {
          width: 300px;
        }
    }

  @media (min-width: 768px) {

  .searchBar_container__GLn3Y[data-menu="false"][data-focused="true"] {
        width: 450px
    }
      }

  @media (min-width: 1024px) {

  .searchBar_container__GLn3Y[data-menu="false"][data-focused="true"] {
        width: 780px
    }
      }

  .searchBar_container__GLn3Y[data-menu="true"] {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .searchBar_container__GLn3Y[data-menu="true"] .searchBar_start__q_sdG,
    .searchBar_container__GLn3Y[data-menu="true"] .searchBar_end__W2CT_ {
  font-size: 65px;
  line-height: 1;
}

  .searchBar_container__GLn3Y[data-menu="true"] .searchBar_form__Hi9xQ {
      height: 36px;
    }

  .searchBar_container__GLn3Y[data-menu="true"] .searchBar_form__Hi9xQ {
  padding-left: 0px;
  padding-right: 0px;
}

  .searchBar_container__GLn3Y[data-menu="true"] .searchBar_dynamic__wxGvT {
      margin-top: 15px;
    }

  .searchBar_container__GLn3Y[data-menu="true"] .searchBar_input__5QIdH {
  padding-top: 0.125rem;
}

  .searchBar_container__GLn3Y[data-menu="true"] .searchBar_input__5QIdH {
  padding-bottom: 0px;
}

  .searchBar_container__GLn3Y[data-menu="true"] .searchBar_border__OjuAK {
  border-width: 7px;
}

  .searchBar_container__GLn3Y {

  position: relative;
}

.searchBar_logo__8goI7 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.searchBar_span__f14CD {
  --tw-text-opacity: 1;
  color: rgba(0, 194, 196, 1);
  color: rgba(0, 194, 196, var(--tw-text-opacity, 1));
}

.searchBar_start__q_sdG,
.searchBar_end__W2CT_ {
  display: none;
  font-weight: 700;
  letter-spacing: -3px;
}

@media (min-width: 768px) {

  .searchBar_start__q_sdG,
.searchBar_end__W2CT_ {
    display: block;
    font-size: 90px;
    line-height: 1;
  }
}

@media (min-width: 1024px) {

  .searchBar_start__q_sdG,
.searchBar_end__W2CT_ {
    font-size: 120px;
    line-height: 1;
  }
}

.searchBar_start__q_sdG,
.searchBar_end__W2CT_ {

  line-height: 80% !important;
}

.searchBar_start__q_sdG {
  --tw-text-opacity: 1;
  color: rgba(255, 148, 91, 1);
  color: rgba(255, 148, 91, var(--tw-text-opacity, 1));
}

.searchBar_end__W2CT_ {
  --tw-text-opacity: 1;
  color: rgba(0, 194, 196, 1);
  color: rgba(0, 194, 196, var(--tw-text-opacity, 1));
}

.searchBar_dynamic__wxGvT {
  margin-bottom: 3px;
  margin-right: 6px;
  min-width: 300px;
  width: 100%;
  position: relative;
}

.searchBar_form__Hi9xQ {
  height: 60px;
}

@media (min-width: 1024px) {

.searchBar_form__Hi9xQ {
    height: 60px;
}
  }

.searchBar_form__Hi9xQ {

  position: relative;

  margin-left: auto;

  margin-right: auto;

  overflow: hidden;

  border-radius: 9999px;

  padding-left: 0.5rem;

  padding-right: 0.5rem;
}

@media (min-width: 1024px) {

  .searchBar_form__Hi9xQ {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
}

.searchBar_border__OjuAK {
  pointer-events: none;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
              -ms-user-select: none;
          user-select: none;
  border-radius: 9999px;
  border-width: 8px;
  --tw-border-opacity: 1;
  border-color: rgba(0, 194, 196, 1);
  border-color: rgba(0, 194, 196, var(--tw-border-opacity, 1));
  background-color: transparent;
}

@media (min-width: 768px) {

  .searchBar_border__OjuAK {
    border-width: 10px;
  }
}

@media (min-width: 1024px) {

  .searchBar_border__OjuAK {
    border-width: 14px;
  }
}

.searchBar_content__nYJ_Y {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.searchBar_input__5QIdH {
  height: 100%;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(253, 253, 253, 1);
  background-color: rgba(253, 253, 253, var(--tw-bg-opacity, 1));
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.25rem;
  padding-right: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgba(0, 194, 196, 1);
  color: rgba(0, 194, 196, var(--tw-text-opacity, 1));
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.searchBar_button__u31Ep {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 148, 91, 1);
  background-color: rgba(255, 148, 91, var(--tw-bg-opacity, 1));
  padding-left: 0.75rem;
  padding-right: 1.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgba(253, 253, 253, 1);
  color: rgba(253, 253, 253, var(--tw-text-opacity, 1));
  outline: 2px solid transparent;
  outline-offset: 2px;
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
}

.searchBar_button__u31Ep:hover {
  --tw-bg-opacity: 0.8;
}

.searchBar_button__u31Ep:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.searchBar_subheading__Dr_7j {
  margin-top: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgba(0, 194, 196, 1);
  color: rgba(0, 194, 196, var(--tw-text-opacity, 1));
}

@media (max-width: 600px) {
  .searchBar_dynamic__wxGvT {
    min-width: auto;
    width: 100%;
  }
}

.results_container__fzXI0 {
  position: absolute;
  left: 0px;
  width: 100%;
  overflow: hidden;
  border-radius: 1rem;
  border-width: 4px;
  --tw-border-opacity: 1;
  border-color: rgba(0, 194, 196, 1);
  border-color: rgba(0, 194, 196, var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgba(253, 253, 253, 1);
  background-color: rgba(253, 253, 253, var(--tw-bg-opacity, 1));
  bottom: auto !important;
  top: calc(100% - 3px);
  -webkit-transform: none;
          transform: none;
}

  .results_container__fzXI0[data-menu="true"] {
  bottom: 1.25rem;
}

  .results_container__fzXI0[data-menu="true"] .results_result__mFrME {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

  .results_container__fzXI0[data-menu="true"] .results_result__mFrME {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.results_result__mFrME {
  cursor: pointer;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, 1);
  border-color: rgba(229, 231, 235, var(--tw-border-opacity, 1));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-align: left;
}

.results_result__mFrME:hover,
  .results_result__mFrME[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: rgba(235, 235, 235, 1);
  background-color: rgba(235, 235, 235, var(--tw-bg-opacity, 1));
}

.results_result__mFrME:hover,
  .results_result__mFrME[data-active="true"] {
  -webkit-transition-duration: 100ms;
          transition-duration: 100ms;
}

.results_title__oMRpi {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
}

.results_subtitle__hHpDS {
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgba(149, 149, 149, 1);
  color: rgba(149, 149, 149, var(--tw-text-opacity, 1));
}

.logoLeft_container__zW7Q7 {
      cursor: pointer;
}
  .logoLeft_container__zW7Q7[data-menu="true"] svg {
      height: 64px;
      margin-bottom: 1px;
    }
  .logoLeft_container__zW7Q7 svg {
    height: 110px;
    width: auto;
  }

.logoRight_container__BizlI {
      cursor: pointer;
}
  .logoRight_container__BizlI[data-menu="true"] svg {
      height: 64px;
      margin-bottom: 1px;
    }
  .logoRight_container__BizlI svg {
    height: 110px;
    width: auto;
  }

.loading_loading__IYoRE {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 10;
      height: 100%;
      width: 100%;
}

.loading_dots__zg0H7 {
      position: absolute;
      top: 2.5rem;
      left: 50%;
      z-index: 10;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      --tw-translate-x: -50%;
      -webkit-transform: translate(-50%, var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
              transform: translate(-50%, var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
      -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
              transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.loading_dots__zg0H7[data-center="true"] {
      top: 50%;
}

.loading_dots__zg0H7[data-center="true"] {
      --tw-translate-y: -50%;
      -webkit-transform: translate(var(--tw-translate-x), -50%) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
              transform: translate(var(--tw-translate-x), -50%) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
      -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
              transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.loading_dot__tCxEW {
      position: relative;
      margin-right: 0.5rem;
      height: 1.25rem;
      width: 1.25rem;
}

.loading_dot__tCxEW:nth-last-child(1) {
      margin-right: 0px;
}

.loading_dot__tCxEW:nth-child(1) .loading_animation__5qgrX {
      -webkit-animation-delay: 0ms;
              animation-delay: 0ms;
    }

.loading_dot__tCxEW:nth-child(2) .loading_animation__5qgrX {
      -webkit-animation-delay: 250ms;
              animation-delay: 250ms;
    }

.loading_dot__tCxEW:nth-child(3) .loading_animation__5qgrX {
      -webkit-animation-delay: 500ms;
              animation-delay: 500ms;
    }

@-webkit-keyframes loading_data-loading__iHUjD {
  0% {
    width: 100%;
    height: 100%;
  }
  50% {
    width: 0%;
    height: 0%;
  }
  100% {
    width: 100%;
    height: 100%;
  }
}

@keyframes loading_data-loading__iHUjD {
  0% {
    width: 100%;
    height: 100%;
  }
  50% {
    width: 0%;
    height: 0%;
  }
  100% {
    width: 100%;
    height: 100%;
  }
}

.loading_animation__5qgrX {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      width: 100%;
      --tw-translate-x: -50%;
      --tw-translate-y: -50%;
      -webkit-transform: translate(-50%, -50%) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
              transform: translate(-50%, -50%) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
      -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
              transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
      border-radius: 9999px;
      --tw-bg-opacity: 1;
      background-color: rgba(0, 194, 196, 1);
      background-color: rgba(0, 194, 196, var(--tw-bg-opacity, 1));

  -webkit-animation-name: loading_data-loading__iHUjD;

          animation-name: loading_data-loading__iHUjD;
  -webkit-animation-duration: 1000ms;
          animation-duration: 1000ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.loading_background__frppZ {
      height: 100%;
      width: 100%;
      --tw-bg-opacity: 1;
      background-color: rgba(253, 253, 253, 1);
      background-color: rgba(253, 253, 253, var(--tw-bg-opacity, 1));
      opacity: 0.9;
}

.location_location___76Hn {
  padding-bottom: 66%;
  position: relative;
  display: block;
  cursor: pointer;
  overflow: hidden;
  border-radius: 0.5rem;
}

  .location_location___76Hn:hover {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  -webkit-box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}

  .location_location___76Hn:hover {
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
}

.location_image__IdYpx {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.location_title__VTjai {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--tw-gradient-stops)));
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
  --tw-gradient-from: #000 var(--tw-gradient-from-position);
  --tw-gradient-to: rgba(0, 0, 0, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
  padding: 1rem;
  padding-bottom: 0.75rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgba(253, 253, 253, 1);
  color: rgba(253, 253, 253, var(--tw-text-opacity, 1));
}

.location_count__t0Zvm {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 1rem;
  white-space: pre;
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgba(253, 253, 253, 1);
  background-color: rgba(253, 253, 253, var(--tw-bg-opacity, 1));
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
}

.carousel_carousel__XLb9i {
  margin-top: 2.5rem;
  display: inline-block;
  width: 100%;
}

  .carousel_carousel__XLb9i:nth-last-child(1) {
  margin-bottom: -2.5rem;
}

.carousel_title__YxUTS {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}

.carousel_top__AiINF {
  margin-bottom: 1rem;
}

.carousel_list___vhSc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  overflow-x: auto;
}

.carousel_list___vhSc::-webkit-scrollbar {
  display: none;
}

.carousel_item__GD3bY {
  /* No Tailwind support */
  -webkit-scroll-snap-align: start;
  scroll-snap-align: start;
  width: 360px;
  margin-right: 1.75rem;
  margin-bottom: 2.5rem;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.carousel_top__AiINF {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.carousel_arrows__tAOgx {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.carousel_arrow__Cq4TT {
  cursor: pointer;
  border-radius: 0.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, 1);
  border-color: rgba(229, 231, 235, var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgba(253, 253, 253, 1);
  background-color: rgba(253, 253, 253, var(--tw-bg-opacity, 1));
  padding: 0.5rem;
}

.carousel_arrow__Cq4TT:hover {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  -webkit-box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}

.carousel_arrow__Cq4TT:hover {
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
}

.carousel_arrow__Cq4TT[data-side="left"] {
  margin-right: 0.5rem;
}

.carousel_arrow__Cq4TT[data-side="left"] svg {
  --tw-rotate: 180deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(180deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(180deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.carousel_arrow__Cq4TT[data-side="left"] svg {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (max-width: 600px) {
  .carousel_item__GD3bY {
    width: 100%;
  }
}

/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
            -ms-user-select: none;
        user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}
.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}
.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list
{
    background: #fff url(/_next/static/media/ajax-loader.0b80f665.gif) center center no-repeat;
}
/* Icons */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url(/_next/static/media/slick.25572f22.eot);
    src: url(/_next/static/media/slick.25572f22.eot?#iefix) format('embedded-opentype'), url(/_next/static/media/slick.653a4cbb.woff) format('woff'), url(/_next/static/media/slick.6aa1ee46.ttf) format('truetype'), url(/_next/static/media/slick.db61df16.svg#slick) format('svg');
}
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}
.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}
.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}
/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}
.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}

