.container {
  @apply cursor-pointer;
  &[data-menu="true"] {
    svg {
      height: 64px;
      margin-bottom: 1px;
    }
  }
  svg {
    height: 110px;
    width: auto;
  }
}
