.loading {
  @apply absolute
    w-full
    h-full
    top-0
    left-0
    z-10;
}

.dots {
  @apply absolute
    top-10
    left-1/2
    transform
    -translate-x-1/2
    z-10
    flex;

  &[data-center="true"] {
    @apply top-1/2 -translate-y-1/2;
  }
}

.dot {
  @apply relative
    w-5
    h-5
    mr-2;

  &:nth-last-child(1) {
    @apply mr-0;
  }

  &:nth-child(1) {
    .animation {
      animation-delay: 0ms;
    }
  }

  &:nth-child(2) {
    .animation {
      animation-delay: 250ms;
    }
  }

  &:nth-child(3) {
    .animation {
      animation-delay: 500ms;
    }
  }
}

@keyframes data-loading {
  0% {
    width: 100%;
    height: 100%;
  }
  50% {
    width: 0%;
    height: 0%;
  }
  100% {
    width: 100%;
    height: 100%;
  }
}

.animation {
  @apply w-full
    h-full
    rounded-full
    absolute
    top-1/2
    left-1/2
    transform
    -translate-x-1/2
    -translate-y-1/2
    bg-green;

  animation-name: data-loading;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.background {
  @apply bg-white
    opacity-90
    w-full
    h-full;
}
