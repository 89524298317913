.container {
  z-index: 9;
  display: flex;
  align-items: center;
  flex-direction: column;

  @screen md {
    width: 400px;
  }

  @screen lg {
    width: 700px;
  }

  &[data-menu="false"] {
    @apply mx-auto
      px-4
      my-40
      duration-300;
    @screen md {
      .input {
        width: 215px;
        transition: 0.3s;
        &:focus {
          width: 300px;
        }
      }
    }

    &[data-focused="true"] {
      @screen md {
        width: 450px;
      }

      @screen lg {
        width: 780px;
      }
    }
  }

  &[data-menu="true"] {
    width: 100%;
    flex-direction: row;

    .start,
    .end {
      @apply text-65;
    }

    .form {
      height: 36px;

      @apply px-0;
    }

    .dynamic {
      margin-top: 15px;
    }

    .input {
      @apply pt-0.5
        pb-0;
    }

    .border {
      @apply border-7;
    }
  }

  @apply relative;
}

.logo {
  @apply flex justify-center;
  align-items: flex-end;
  justify-content: flex-start;
}

.span {
  @apply text-green;
}

.start,
.end {
  @apply font-bold
  tracking-negative
  hidden
  md:text-90
  md:block
  lg:text-120;

  line-height: 80% !important;
}

.start {
  @apply text-orange;
}

.end {
  @apply text-green;
}

.dynamic {
  margin-bottom: 3px;
  margin-right: 6px;
  min-width: 300px;
  width: 100%;

  @apply relative;
}

.form {
  height: 60px;

  @screen lg {
    height: 60px;
  }

  @apply relative
    mx-auto
    px-2
    rounded-full
    overflow-hidden
    lg:px-3.5;
}

.border {
  @apply border-8
    rounded-full
    absolute
    top-0
    left-0
    bg-transparent
    w-full
    h-full
    select-none
    pointer-events-none
    border-green
    md:border-10
    lg:border-14;
}

.content {
  @apply flex
    h-full;
}

.input {
  @apply pl-5
    pr-3
    py-4
    font-bold
    text-sm
    outline-none
    h-full
    w-full
    bg-white
    text-green;
}

.button {
  @apply duration-150
	bg-orange
    text-white
    text-xs
    font-bold
    pl-3
    pr-5
    outline-none;

  &:hover {
    @apply bg-opacity-80;
  }

  &:focus {
    @apply outline-none;
  }
}

.subheading {
  @apply text-center
    text-green
    px-4
    font-bold
    mt-12;
}
@media (max-width: 600px) {
  .dynamic {
    min-width: auto;
    width: 100%;
  }
}
