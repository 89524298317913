.location {
  padding-bottom: 66%;

  @apply rounded-lg
    cursor-pointer
    overflow-hidden
    relative
    block;

  &:hover {
    @apply shadow-xl
		  duration-150;
  }
}

.image {
  @apply absolute
    w-full
    h-full
    top-0
    left-0
    object-cover;
}

.title {
  @apply font-bold
    p-4
    pb-3
    w-full
    text-xl
    absolute
    bottom-0
    left-0
    text-white
    bg-gradient-to-t
    from-black
    to-transparent;
}

.count {
  @apply font-bold
    absolute
    top-0
    left-0
    px-2
    py-1
    m-4
    bg-white
    rounded-md
    text-sm
    whitespace-pre;
}
