.container {
  @apply absolute
    left-0
    border-4
    w-full
    rounded-2xl
    bg-white
    overflow-hidden
    border-green;
  bottom: auto !important;
  top: calc(100% - 3px);
  transform: none;

  &[data-menu="true"] {
    @apply bottom-5;

    .result {
      @apply px-3
        py-2;
    }
  }
}

.result {
  @apply text-left
    border-gray-200
    px-4
    py-3
    border-b
    cursor-pointer;

  &:hover,
  &[data-active="true"] {
    @apply bg-divider
      duration-100;
  }
}

.title {
  @apply font-bold
    text-sm;
}

.subtitle {
  @apply text-xs
    text-dark-gray;
}
