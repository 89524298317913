.container {
  @apply self-center;
  display: flex;
  margin-top: 15px;
  align-items: center;
}

.count {
  @apply cursor-pointer
  font-bold;

  &:hover {
    @apply text-green;
  }
}
.logo {
  @apply cursor-pointer;
  display: none;
}
@media (max-width: 767px) {
  .logo {
    display: flex;
  }
}

.span {
  @apply text-green;
}
@media (max-width: 1200px) {
  .count {
    display: none;
  }
}
